import React, { useContext, useState, useEffect } from "react"
import "./style.scss"
import CartContext from "../../context/cartContext"
import PrimarySelect from "../PrimarySelect"
import { inlineLabels, selectOptions } from "./mockedData"
import {getCadence, getInlinePrescription} from "../../services/utils"
import {julyPriceTestClassic, quantityOptionsValues} from "../ContactsSummary/mockedData"
import { getValues } from "../../services/utils"
import WebpImage from "../WebpImage"
import { usePromo, promoEnd, promoStart } from "../../utils/usePromo"
import useIsLifemart from "../Lifemart/hooks/useIsLifemart"
import useIsMilitary from "../Military/hooks/useIsMilitary"
import {getHubblePricing, hubbleClassic} from "../../services/data";

const ProductWrapper = ({
  cart_item_id,
  title,
  image,
  type,
  children,
  isHydro,
}) => {
  const { removeItemCart } = useContext(CartContext)

  return (
    <div className="product-wrapper" data-type={type}>
      <div className="head-block">
        <div className="image-container">
          <WebpImage objectFit="contain" fileName={image} />
        </div>
        <p className="title text h7 neue-bold">{title}</p>
      </div>
      <div className="body-block">{children}</div>
      <a
        className="remove text h8 neutral"
        onClick={() => removeItemCart(cart_item_id)}
      >
        Remove product
      </a>
    </div>
  )
}

const QuantityWrapper = ({ quantity, price, id }) => {
  const { setQuantity } = useContext(CartContext)

  const totalPrice = quantity * price

  return (
    <div className="quantity-wrapper">
      <PrimarySelect
        values={selectOptions}
        selectedValue={quantity}
        setValue={e => setQuantity(id, e.target.value)}
      />
      <span className="price text h7 neue-bold">${totalPrice.toFixed(2)}</span>
    </div>
  )
}

export const AccessoryCard = ({
  cart_item_id,
  image,
  title,
  description,
  quantity,
  price,
}) => (
  <ProductWrapper {...{ cart_item_id, image, title }} type="accessory">
    <p className="description text h10 black">{description}</p>
    <QuantityWrapper id={cart_item_id} quantity={quantity} price={price} />
  </ProductWrapper>
)

export const ContactsClassicCard = ({
  cart_item_id,
  title,
  cadence,
  lens_quantity,
  prescription,
  price,
  sale_price,
  image,
  isOneEye,
  nonSubscription,
  filteredPrescriptionObj,
  sku,
  is_duplicate_item,
}) => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary() && lens_quantity === 30
  const hubblePricing = getHubblePricing()

  const itemImage = "Pages/ContactsPCP/Hubble-Classic-Lenses.png"
  const productTitle = !nonSubscription
    ? `${title} Subscription`
    : "Single Purchase Hubble Daily Contact Lenses"
  const recurringOrderPrice =
    isMilitary && lens_quantity === 30
      ? getValues(!isLifemart && !isMilitary ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic, lens_quantity, "militaryPrice")
      : isLifemart && lens_quantity === 30
      ? getValues(!isLifemart && !isMilitary ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic, lens_quantity, "lifemartPrice")
      : getValues(!isLifemart && !isMilitary ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic, lens_quantity, "price")


  return (
    <ProductWrapper
      {...{ cart_item_id, image: itemImage, isOneEye }}
      title={productTitle}
      type="hubble"
    >
      <p className="period text h10">
        {nonSubscription ? "" : "Every"} {cadence}{" "}
        {lens_quantity === 30 ? "Weeks" : "Months"}{" "}
        {nonSubscription ? "Supply" : ""}
      </p>
      <div className="prescription">
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => {
            if (values.power === "oneEye") return
            return (
              <p className="text h10 granite" key={index}>{`${
                inlineLabels[eye]
              }: ${getInlinePrescription(values)}`}</p>
            )
          })}
      </div>
      <div className="starter-pack">
        <div className="price-wrapper">
          <span className="text h7 neue-bold">
            {nonSubscription || is_duplicate_item
              ? `Your ${lens_quantity} ${isOneEye ? "lenses" : "pairs"}`
              : `${hubbleClassic.trialPeriod}-day starter pack`}
          </span>
          <span className="text h7 neue-bold">
            ${price * ((!isOneEye && Number(price) !== 1) ? 2 : 1)}
            {!nonSubscription && "*"}
          </span>
        </div>
        {!nonSubscription && (
          <>
            {!isOneEye ? (
              <p className="disclaimer text h10 granite">
                *recurring orders are ${+recurringOrderPrice * 2}{" "}
                {(isMilitary || isLifemart) && lens_quantity === 30 ? "(15% off)" : ""} for both eyes, and
                {is_duplicate_item ? "shipped & billed every 4 weeks" : `begin after ${hubbleClassic.trialPeriod}-day starter pack`} (depending on doctor
                communication)
              </p>
            ) : (
              <p className="disclaimer text h10 granite">
                *recurring orders are $
                {(isMilitary || isLifemart) && lens_quantity === 30
                  ? `${recurringOrderPrice} (15% off)`
                  : `${getValues(
                    !isLifemart && !isMilitary ? [julyPriceTestClassic] : quantityOptionsValues(hubblePricing).classic,
                      lens_quantity,
                      "price"
                    )}`}
                , and {is_duplicate_item ? "shipped & billed every 4 weeks" : `begin after ${hubbleClassic.trialPeriod}-day starter pack`} (depending on doctor
                communication)
              </p>
            )}
          </>
        )}
      </div>
    </ProductWrapper>
  )
}

export const ContactsHydroCard = ({
  cart_item_id,
  title,
  cadence,
  lens_quantity,
  prescription,
  price,
  isOneEye,
  isHydro,
  isSkyhy,
  nonSubscription,
  filteredPrescriptionObj,
}) => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary() && lens_quantity === 30
  const isPromo = usePromo(promoStart, promoEnd)
  const hubblePricing = getHubblePricing()

  const image = isHydro
    ? "Pages/ContactsPCP/Hubble-Hydro-Lenses.png"
    : "Pages/ContactsPCP/Hubble-SkyHy-Lenses.png"
  const shipmentCadence = `${cadence} ${
    lens_quantity === 30 ? "weeks" : "months"
  } `
  const optionsValues = isHydro
    ? quantityOptionsValues(hubblePricing).hydro
    : quantityOptionsValues(hubblePricing).skyhy
  const eyeMultiplier = !isOneEye ? 2 : 1
  const lifemartPrice = getValues(optionsValues, lens_quantity, "lifemartPrice")
  const militaryPrice = getValues(optionsValues, lens_quantity, "militaryPrice")
  const regularPrice = getValues(optionsValues, lens_quantity, "price")

  const recurringOrderPrice = isMilitary
    ? militaryPrice
    : isLifemart && lens_quantity === 30
    ? lifemartPrice
    : regularPrice
  const monthDiscountedPrice = price * (isOneEye ? 1 : 2)
  const totalPrice =
    getValues(optionsValues, lens_quantity, "price") * eyeMultiplier
  const product = isHydro ? "Hydro" : isSkyhy ? "Skyhy" : "Lenses"
  const productTitle = !nonSubscription
    ? `${title} Subscription`
    : `Single Purchase ${product} by Hubble`

    return (
    <ProductWrapper
      {...{ cart_item_id, image, isOneEye, isHydro, isSkyhy, nonSubscription }}
      title={productTitle}
      type="hubble"
    >
      <p className="period text h10">
        {nonSubscription ? "" : "Every"} {cadence}{" "}
        {lens_quantity === 30 ? "Weeks" : "Months"}{" "}
        {nonSubscription ? "Supply" : ""}
      </p>
      <div className="prescription">
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => {
            if (values.power === "oneEye") return
            return (
              <p className="text h10 granite" key={index}>{`${
                inlineLabels[eye]
              }: ${getInlinePrescription(values)}`}</p>
            )
          })}
      </div>
      <div className="starter-pack">
        <div className="price-wrapper">
          <div>
            <p className="text h7 neue-bold">
              Your {nonSubscription ? "" : "first"} {lens_quantity}{" "}
              {isOneEye ? "lenses" : "pairs"}
            </p>
            <p className="text h7 neue-bold">
              ({cadence} {lens_quantity === 30 ? "weeks" : "months"} supply)
            </p>
          </div>
          {!isMilitary && !isLifemart && (
            <span className="text h7 neue-bold">
              ${lens_quantity === 30 ? monthDiscountedPrice : totalPrice}
              {!nonSubscription && "*"}
            </span>
          )}
          {(isMilitary || isLifemart) && lens_quantity === 30 && (
            <span className="text h7 neue-bold">
              ${monthDiscountedPrice}
              {!nonSubscription && "*"}
              <span className="discount-info">
                {isHydro ? "(80% off)" : "(66% off)"}
              </span>
            </span>
          )}
          {(isMilitary || isLifemart) && lens_quantity !== 30 && (
            <span className="text h7 neue-bold">
              ${totalPrice}
              {!nonSubscription && "*"}
              <span className="discount-info">
                ({getValues(optionsValues, lens_quantity, "discount")} off)
              </span>
            </span>
          )}
        </div>
        {!nonSubscription && (
          <p className="disclaimer text h10 granite">
            *recurring orders are ${recurringOrderPrice}{" "}
            {(isMilitary || isLifemart) && lens_quantity === 30
              ? "(15% off)"
              : ""}
            {(isMilitary) && lens_quantity !== 30
              ? `(${getValues(
                  optionsValues,
                  lens_quantity,
                  "discount"
                )} + 15% off)`
              : ""}{" "}
            {isOneEye ? "" : "per eye"}, and shipped & billed every{" "}
            {shipmentCadence}
            (depending on doctor communication)
          </p>
        )}
      </div>
    </ProductWrapper>
  )
}

export const AvgCard = ({
  cart_item_id,
  title,
  cadence,
  lens_quantity,
  prescription,
  price,
  sale_price,
  image,
  isOneEye,
  nonSubscription,
  filteredPrescriptionObj,
  sku
}) => {
  const [isMilitary, setIsMilitary] = useState(false)
  const isLifemart = useIsLifemart()

  const productTitle = !nonSubscription
    ? `${title} Subscription`
    : "Single Purchase Hubble Daily Contact Lenses"

  useEffect(() => {
    const isMilitaryFromStorage = !!localStorage.getItem("isMilitary")
    setIsMilitary(isMilitaryFromStorage && lens_quantity === 30)
  }, [])

  return (
    <ProductWrapper
      {...{ cart_item_id, image, isOneEye }}
      title={productTitle}
      type="hubble"
    >
      <p className="period text h10">
        {getCadence(cadence)}
      </p>
      <div className="prescription">
        {Object.entries(filteredPrescriptionObj)
          .sort()
          .reverse()
          .map(([eye, values], index) => {
            if (values.power === "oneEye") return
            return (
              <p className="text h10 granite" key={index}>{`${
                inlineLabels[eye]
              }: ${getInlinePrescription(values)}`}</p>
            )
          })}
      </div>
      <div className="starter-pack">
        <div className="price-wrapper">
          <span className="text h7 neue-bold">
            {nonSubscription || `Your ${lens_quantity} ${isOneEye ? "lenses" : "pairs"}`}
          </span>
          <span className="text h7 neue-bold">
            ${((sale_price || price) * (isOneEye ? 1 : 2)).toFixed(2)}
            {!nonSubscription && "*"}
          </span>
        </div>
        {!nonSubscription && (
          <p className="disclaimer text h10 granite">
            *recurring orders are ${parseFloat(price).toFixed(2)}{" "} per eye, and
            begin after your first order (depending on doctor communication)
          </p>
        )}
      </div>
    </ProductWrapper>
  )
}

export const ProductCard = ({
  cart_item_id,
  image,
  title,
  colorTitle,
  price,
  quantity,
  type,
  isLifemart,
}) => { 
  const calculatedPrice = isLifemart ? (price * 0.75).toFixed(2) : price

  return (
  <ProductWrapper
    {...{ cart_item_id, image }}
    title={type === "glasses" ? `${title} Glasses` : `${title} Sunglasses`}
    type="product"
  >
    <p className="description text h10 black">
      Free Protective case and microfiber cleaning cloth included
    </p>
    <p className="price-info text h10 granite">{`${colorTitle}, Frame ($${calculatedPrice})`}</p>
    <QuantityWrapper quantity={quantity} price={calculatedPrice} id={cart_item_id} />
  </ProductWrapper>
)}
